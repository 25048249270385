import React from 'react';

const AtlassianDomainVerification = (props) => {

    return (
        <div>
            <h1>Instructions</h1>
            <h2>What is this file?</h2>
            This file is used by Atlassian Cloud to verify ownership of your domain name.
            <br />
            Generated at 2023-08-22 06:14:35 UTC.
            <br />
            <br />

            <h2>I have direct access to my web server</h2>
            <ul>
                <li>Upload this file to the root folder (top-level folder) of your domain's website.</li>
                <li>The file must be accessible at: <code>https://example.com/atlassian-domain-verification.html</code></li>
                <li>Return to Atlassian admin screen to complete the verification process</li>
            </ul>

            <h4>OR</h4>

            <h2>I am using a CMS or other managed service</h2>
            <ul>
                <li>Create a page with the path: <code>atlassian-domain-verification.html</code></li>
                <li>Insert this token somewhere into the page: <code>KzjKguaiVFDUviv2d2PZyloV/ZRJb5EvxucQJ4IQLbyQ1o7NnKyCHKbauEs9JyiK</code></li>
                <li>For security reasons please ensure that only site administrators can edit or post comments to this page.</li>
                <li>Return to Atlassian admin screen to complete the verification process</li>
            </ul>

            <br />

            <h2>Troubleshooting</h2>
            <ul>
                <li>For further information please visit the
                    <a href="https://confluence.atlassian.com/purchasing/managed-accounts-and-domain-claim-verification-846071711.html">
                        managed accounts and domain claim verification</a> page.</li>
                <li>A single redirect is allowed, to: <code>https://www.example.com/atlassian-domain-verification.html</code></li>
            </ul>

            <br />

            <h1>Domain Verification Token</h1>
            This token must be found in the page at: <code>https://example.com/atlassian-domain-verification.html</code>
            <hr />
            <code id="domain-verification-token" data-value="KzjKguaiVFDUviv2d2PZyloV/ZRJb5EvxucQJ4IQLbyQ1o7NnKyCHKbauEs9JyiK">KzjKguaiVFDUviv2d2PZyloV/ZRJb5EvxucQJ4IQLbyQ1o7NnKyCHKbauEs9JyiK</code>
            {/* KzjKguaiVFDUviv2d2PZyloV/ZRJb5EvxucQJ4IQLbyQ1o7NnKyCHKbauEs9JyiK */}
        </div>
    );
};

export default AtlassianDomainVerification;
